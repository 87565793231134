import React from 'react';
import { connect } from 'react-redux';

import { uiOperations } from '../models/ui';

// Non-navigable
import LoadingOverlay from '../components/loading-overlay';
import LoadingErrorModal from '../components/loading-error-modal';
import AuthenticationErrorModal from '../components/authentication-error-modal';
import ContentErrorModal from '../components/content-error-modal';
import ExternalLinkModal, {
  ID as ExternalLinkModalID,
} from '../components/external-link-modal';
import Dialog from './ui/dialog';
import OnboardingDialog from '../components/onboarding-dialog';
import NameRequiredDialog from '../components/name-required-dialog';
import PrivateProfileDialog from '../components/private-profile-dialog';
import SubmissionArchiveConfirmDialog from '../screens/content-submission/archive-dialog';
import SubmissionResumeAutoSaveDialog from '../screens/content-submission/resume-dialog';
import CommentReportConfirmDialog from '../components/comments/comment-report-confirm-dialog';
import CommentDeleteConfirmDialog from '../components/comments/comment-delete-confirm-dialog';
import DiscardChangesDialog from '../components/discard-changes-dialog';
import AvatarFormDialog from '../components/avatar-form-dialog';
import PasswordFormDialog from '../components/password-form-dialog';
import InviteUserModal from '../components/invite-user-modal';
import { ContentCardShareModalOverlay } from './v2/content-card/content-card-share-modal/content-card-share-modal';
import { ID as LoadingOverlayID } from '../components/loading-overlay/loading-overlay';
import { ID as LoadingErrorModalID } from '../components/loading-error-modal/loading-error-modal';
import { ID as AuthenticationErrorModalID } from '../components/authentication-error-modal/authentication-error-modal';
import { ID as ContentErrorModalID } from '../components/content-error-modal/content-error-modal';
import { ID as DialogID } from './ui/dialog';
import { ID as OnboardingDialogID } from '../components/onboarding-dialog';
import { ID as NameRequiredDialogID } from '../components/name-required-dialog';
import { ID as PrivateProfileDialogID } from '../components/private-profile-dialog';
import { ID as SubmissionArchiveConfirmDialogID } from '../screens/content-submission/archive-dialog';
import { ID as SubmissionResumeAutoSaveDialogID } from '../screens/content-submission/resume-dialog';
import { ID as CommentReportConfirmDialogID } from '../components/comments/comment-report-confirm-dialog';
import { ID as CommentDeleteConfirmDialogID } from '../components/comments/comment-delete-confirm-dialog';
import { ID as DiscardChangesDialogID } from '../components/discard-changes-dialog/discard-changes-dialog';
import { ID as AvatarFormDialogID } from '../components/avatar-form-dialog/avatar-form-dialog';
import { ID as PasswordFormDialogID } from '../components/password-form-dialog/password-form-dialog';
import { ID as ContentCardShareModalID } from '../components/v2/content-card/content-card-share-modal/content-card-share-modal';
import { ID as InviteUserModalID } from '../components/invite-user-modal/invite-user-modal';
import {
  ID as ViewLikesModalID,
  ViewLikesModal,
} from './v2/content-actions/view-likes-modal/view-likes-modal';
import {
  ID as ConfirmFlagContentModalID,
  ConfirmFlagContentModal,
} from './v2/content-actions/confirm-flag-content-modal/confirm-flag-content-modal';

import {
  ID as CopyLinkModalID,
  CopyLinkModal,
} from './v2/content-actions/copy-link-modal/copy-link-modal';
import {
  ShortcutMobileListModal,
  ID as ShortcutMobileListModalId,
} from './ui/shortcut/modal/shortcut-mobile-list-modal';
import {
  ShortcutModal,
  ID as ShortcutModalId,
} from './ui/shortcut/modal/shortcut-modal';

class OverlayController extends React.Component {
  overlayMap = {
    [ContentCardShareModalID]: ContentCardShareModalOverlay,
    [LoadingOverlayID]: LoadingOverlay,
    [LoadingErrorModalID]: LoadingErrorModal,
    [AuthenticationErrorModalID]: AuthenticationErrorModal,
    [ContentErrorModalID]: ContentErrorModal,
    [ExternalLinkModalID]: ExternalLinkModal,
    [DialogID]: Dialog,
    [OnboardingDialogID]: OnboardingDialog,
    [NameRequiredDialogID]: NameRequiredDialog,
    [PrivateProfileDialogID]: PrivateProfileDialog,
    [SubmissionArchiveConfirmDialogID]: SubmissionArchiveConfirmDialog,
    [SubmissionResumeAutoSaveDialogID]: SubmissionResumeAutoSaveDialog,
    [CommentReportConfirmDialogID]: CommentReportConfirmDialog,
    [CommentDeleteConfirmDialogID]: CommentDeleteConfirmDialog,
    [DiscardChangesDialogID]: DiscardChangesDialog,
    [AvatarFormDialogID]: AvatarFormDialog,
    [PasswordFormDialogID]: PasswordFormDialog,
    [InviteUserModalID]: InviteUserModal,
    [ViewLikesModalID]: ViewLikesModal,
    [ConfirmFlagContentModalID]: ConfirmFlagContentModal,
    [CopyLinkModalID]: CopyLinkModal,
    [ShortcutMobileListModalId]: ShortcutMobileListModal,
    [ShortcutModalId]: ShortcutModal,
  };

  componentDidUpdate() {
    if (this.props.overlays.length) {
      if (
        this.props.overlays.some(
          (overlay) => overlay.props.shouldSnapToTop === false
        )
      ) {
        return; // don't add class that jumps the screen to the top
      } else {
        document.body.classList.add('modal-open');
      }
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  render() {
    return (
      <>
        {this.overlayComponents}
        {this.props.showLoading ? <LoadingOverlay transparent /> : null}
      </>
    );
  }

  handleClose = (overlay) => {
    const closeFn =
      (overlay.props && (overlay.props.close || overlay.props.onClose)) ||
      this.props.removeOverlay;

    closeFn(overlay);
  };

  get overlayComponents() {
    const overlayComponent = (overlay) => {
      const Component = this.overlayMap[overlay.id];

      if (!Component) {
        console.error(`Overlay with id ${overlay.id} not found`);
        return null;
      }

      return (
        <Component
          {...overlay.props}
          close={() => this.handleClose(overlay)}
          key={overlay.key || overlay.id}
        />
      );
    };

    return this.props.overlays.map(overlayComponent);
  }
}

const mapStateToProps = (state) => ({
  overlays: state.ui.overlays,
  showLoading: state.ui.showLoading,
});

const mapDispatchToProps = {
  removeOverlay: uiOperations.removeOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayController);
