import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { programSelectors } from '../../models/program';
import { programMembershipSelectors } from '../../models/program-membership';
import { Icon, Link, Profile } from '../ui';
import SiteHeaderSubmit from './site-header-submit';
import { RootPatronState } from '../../common/use-patron-selector';
import Tooltip from '../tooltip/tooltip';
import { uiSelectors } from '../../models/ui';

const ProfileLink = ({ linkProps = {} }) => {
  const { t } = useTranslation();

  const label = t('nav_links.profile');

  return (
    <Tooltip aria-hidden text={label} options={{ placement: 'bottom' }}>
      <Link
        href="/account/profile"
        action="push"
        className="profile-link"
        aria-label={label}
        {...linkProps}
      >
        <Profile />
      </Link>
    </Tooltip>
  );
};

const DiscoverLink = ({ linkProps = {} }) => {
  const { t } = useTranslation();

  const label = t('nav_links.discover');

  return (
    <Tooltip aria-hidden text={label} options={{ placement: 'bottom' }}>
      <Link
        href="/discover"
        action="push"
        className="discover-link"
        aria-label={label}
        {...linkProps}
      >
        <Icon type="search" />
      </Link>
    </Tooltip>
  );
};

type SiteHeaderLinksProps = ReturnType<typeof mapStateToProps>;

const SiteHeaderLinks = ({
  membershipIsActive,
  canSubmitContent,
  searchBarInHeaderEnabled,
  isMinimized,
}: SiteHeaderLinksProps) => {
  const tabIndexProps = isMinimized ? { tabIndex: -1 } : {};
  return (
    <nav className="quick-links assistant-enabled">
      {searchBarInHeaderEnabled ? null : (
        <DiscoverLink linkProps={tabIndexProps} />
      )}
      {membershipIsActive && canSubmitContent ? <SiteHeaderSubmit /> : null}

      <ProfileLink linkProps={tabIndexProps} />
    </nav>
  );
};

const mapStateToProps = (state: RootPatronState) => ({
  canSubmitContent: programSelectors.getProgramCanSubmitContent(state),
  membershipIsActive:
    programMembershipSelectors.getProgramMembershipIsActive(state),
  searchBarInHeaderEnabled: programSelectors.getSearchBarInHeaderEnabled(state),
  isMinimized: uiSelectors.getMinimizeHeader(state),
});

export default connect(mapStateToProps)(SiteHeaderLinks);
