import { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Modal from '../../modal';
import './shortcut-modal.scss';
import {
  Icon,
  IconButton,
  TextInput,
} from '@socialchorus/shared-ui-components';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../models/assistant/interfaces/Button';
import { AssistantAction } from '../../../assistant';
import { trackAssistantIntegrationLinkClick } from '../../../../models/assistant/analytics';
import Spinner from '../../spinner';
import useScreenSize from '../../../../common/use-screen-size';

const SEARCH_BAR_COUNT_REQUIREMENT = 20;

export const ID = 'Overlays.SHORTCUT_MODAL';
export type ShortcutCommand = { url: string; label: string };

export type ShortcutModalProps = {
  close: () => void;
  id?: string;
  coverImage?: string;
  title: string;
  description?: string;
  items: ShortcutCommand[] | Button[];
  isLoading?: boolean;
};

const areShortcutCommands = (
  items: ShortcutModalProps['items']
): items is ShortcutCommand[] => {
  return (items as ShortcutCommand[])[0].url !== undefined;
};

const itemClassNames = cx(
  'pt-secondary-color',
  'text-body-2',
  'shortcut-modal__body--links'
);

export function ShortcutModal({
  close,
  id,
  coverImage,
  title,
  description,
  items,
  isLoading,
}: ShortcutModalProps) {
  const [searchFilter, setSearchFilter] = useState('');
  const { t } = useTranslation();

  const { isMobile } = useScreenSize();

  const handleIntegrationLinkClick = useCallback(
    (button: Button, order: number) => {
      trackAssistantIntegrationLinkClick({
        service_id: id,
        service_name: title,
        link_name: button.button_text,
        order,
      });
    },
    [id, title]
  );

  const filteredItems = useMemo(() => {
    if (searchFilter == null || searchFilter == '') return items;
    const text = searchFilter.toLowerCase();

    if (areShortcutCommands(items)) {
      return items.filter((link) => {
        return link.label.toLowerCase().indexOf(text) > -1;
      });
    } else {
      return items.filter((button) => {
        return button.button_text.toLowerCase().indexOf(text) > -1;
      });
    }
  }, [items, searchFilter]);

  useEffect(() => {
    setSearchFilter('');
  }, [id]);

  const coverImageElement = coverImage && (
    <img
      src={coverImage}
      alt=""
      role="presentation"
      className="shortcut-modal__banner"
    />
  );

  return (
    <Modal className="shortcut-modal" lockScroll onClose={close}>
      <div className="shortcut-modal__close">
        <IconButton
          iconName={isMobile ? 'arrow_back' : 'close'}
          onClick={close}
        />
      </div>
      {isMobile && coverImageElement}
      <header className="shortcut-modal__header">
        <div className="shortcut-modal__header--headings">
          <div
            className={cx(
              isMobile ? 'text-main-heading' : 'text-tertiary-heading'
            )}
          >
            {isLoading ? t('common.loading') : title}
          </div>
          <div className="text-caption text-black-60">{description}</div>
        </div>
      </header>
      {!isMobile && coverImageElement}
      <div className="shortcut-modal__body">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            {items.length > SEARCH_BAR_COUNT_REQUIREMENT && (
              <div className="shortcut-modal__body--search-container">
                <TextInput
                  className="shortcut-modal__body--search"
                  leftWidget={<Icon size={16}>search</Icon>}
                  size="compact"
                  placeholder={t('search.shortcut_search_placeholder')}
                  value={searchFilter}
                  onChange={(value) => setSearchFilter(value)}
                />
              </div>
            )}
            <ul className="shortcut-modal__body--list">
              {filteredItems.length > 0 &&
                (areShortcutCommands(filteredItems)
                  ? filteredItems.map((link) => (
                      <li key={link.url + link.label}>
                        <a
                          href={link.url} //no tracking as TLP shortcuts are not tracked at this time. (separate bug?)
                          target="_blank"
                          rel="noopener noreferrer"
                          className={itemClassNames}
                        >
                          {link.label}
                        </a>
                      </li>
                    ))
                  : filteredItems.map(
                      (button, index) =>
                        button.action && (
                          <li key={button.id}>
                            <AssistantAction
                              onClick={() =>
                                handleIntegrationLinkClick(button, index)
                              }
                              action={button.action}
                              actionTitle={button.button_text}
                              asCommand
                              className={itemClassNames}
                            >
                              {button.button_text}
                            </AssistantAction>
                          </li>
                        )
                    ))}
            </ul>
          </>
        )}
      </div>
    </Modal>
  );
}
