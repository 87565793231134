import { MouseEventHandler, useEffect } from 'react';
import Modal from '../../modal';
import './shortcut-mobile-list-modal.scss';
import { useTranslation } from 'react-i18next';
import useScreenSize from '../../../../common/use-screen-size';
import { usePatronSelector } from '../../../../common/use-patron-selector';
import { assistantSelectors } from '../../../../models/assistant';
import { IconButton } from '@socialchorus/shared-ui-components';
import Link from '../../link';
import { trackAssistantIntegrationLinkClick } from '../../../../models/assistant/analytics';
import useAssistantActionHandlers from '../../../../common/use-assistant-action-handlers';

export const ID = 'Overlays.SHORTCUT_MOBILE_LIST_MODAL';
export type ShortcutCommand = { url: string; label: string };

export type ShortcutModalProps = {
  close: () => void;
};

export function ShortcutMobileListModal({ close }: ShortcutModalProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { handleAction } = useAssistantActionHandlers();

  const integrations = usePatronSelector((s) =>
    (assistantSelectors.getIntegrations(s) || []).filter((integration) => {
      return (
        integration?.subject?.integrationType &&
        integration.subject.integrationType !== 'semantic_search'
      );
    })
  );

  useEffect(() => {
    if (!isMobile) {
      close();
    }
  }, [isMobile, close]);

  return (
    <Modal className="shortcut-mobile-modal" lockScroll onClose={close}>
      <div className="shortcut-mobile-modal__close">
        <IconButton iconName={'arrow_back'} onClick={close} />
      </div>
      <header className="shortcut-mobile-modal__header font-semibold text-tertiary-heading">
        {t('navigation.shortcuts')}
      </header>
      <div className="shortcut-mobile-modal__body">
        {integrations.map((integration) => {
          const isTLC = integration?.subject?.integrationType === 'link';
          const integrationActions = integration?.subject?.buttons;

          const directAction = isTLC && integrationActions?.length === 1;

          const directActionClick: MouseEventHandler<HTMLAnchorElement> = (
            e
          ) => {
            if (directAction) {
              e.preventDefault();
              const button = integrationActions[0];

              trackAssistantIntegrationLinkClick({
                service_id: integration.id,
                service_name: integration.subject.title,
                link_name: button.button_text,
                order: 0,
              });

              handleAction(integrationActions[0]?.action);
            }
          };

          const onClick = directAction ? directActionClick : () => close();
          const href = directAction
            ? undefined
            : `/assistant/service/${integration?.id}`;

          return (
            <Link
              className="shortcut-mobile-modal__body--item"
              href={href}
              onClick={onClick}
              action="push"
              key={integration.id}
            >
              <img
                className="shortcut-mobile-modal__body--item-logo"
                src={integration.subject.image_url}
                alt=""
              />
              <div className="shortcut-mobile-modal__body--item-text">
                <div className="text-body-2">{integration.subject.title}</div>
                <div className="text-caption text-black-60">
                  {integration.subject.description}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </Modal>
  );
}
